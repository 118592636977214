<template>
  <NuxtLayout name="base">
    <div class="flex flex-col min-h-dvh">
      <AppHeader sticky />
      <div class="flex-grow min-w-0 container mx-auto p-4 sm:p-6 lg:p-8">
        <slot />
      </div>
      <AppFooter />
    </div>
  </NuxtLayout>
</template>

<script lang="ts" setup>
import { geolocationToLocation } from '~/lib/search'
import { AppProviderKeys } from '~/providers'

import type { IGooglePlace } from '~/types/common/google-place'
import type { IPSearchParameters } from '~/types/provider/search-parameters'
import type { ISearchParameters } from '~/types/search/search'

const geolocation = useGeolocation()
const { locale } = useI18n()
const i18nHead = useLocaleHead({
  addSeoAttributes: true
})

useHead({
  link: [...(i18nHead.value.link || [])],
  htmlAttrs: {
    lang: i18nHead.value?.htmlAttrs?.lang || locale
  },
  meta: [...(i18nHead.value.meta || [])]
})

const searchParameters: ISearchParameters = reactive({})

provide<IPSearchParameters>(AppProviderKeys.SearchParameters, {
  searchParameters,
  updateLocation: (value?: IGooglePlace): void => {
    searchParameters.location = value
  },
  updateFilter: () => {
    throw new Error('not supported')
  },
  updateFilters: () => {
    throw new Error('not supported')
  },
  updateSortOrder: () => {
    throw new Error('not supported')
  }
})

searchParameters.location = geolocationToLocation(geolocation)
</script>
